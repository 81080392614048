import { UserInstructionSet } from '@/interfaces/common.interface'

// #region General

const general_ShowIf: UserInstructionSet = {
  topic: 'Display Criteria',
  pages: [
    { heading: 'Number of Conditions', description: 'Choose whether there will be one or more conditions.', image: require('@/assets/img/instructions/general/show-if/show-if-type.png') },
    { heading: 'Single Condition', description: 'Choose whether the condition will be based on a field or option group. Then choose the field or option group, operator, and value to which to compare the field or option.', image: require('@/assets/img/instructions/general/show-if/show-if-single.png') },
    { heading: 'Multiple Conditions', description: 'Create one or more groups of conditions, and choose whether any or all groups must be true.', image: require('@/assets/img/instructions/general/show-if/show-if-multiple.png') },
    { heading: 'Add Conditions', description: 'Add conditions to each group, and choose whether any or all conditions must be true for each group.', image: require('@/assets/img/instructions/general/show-if/show-if-multiple-conditions.png') }
  ]
}

const general_SaveAndDeploy: UserInstructionSet = {
  topic: 'Save and Deploy',
  pages: [
    { heading: 'Save', description: 'To save all your changes click the "Save" button at the bottom of the page. This stores a new revision containing any changes you\'ve made but does not make them live.', image: require('@/assets/img/instructions/general/save-button.png') },
    { heading: 'Deploy the Changes', description: 'Click "Deploy Live" at the bottom of the Changelog to make the new revision live.', image: require('@/assets/img/instructions/general/changelog-deploy.png') }
  ]
}

// #region Registration Form

const registrationForm_AddPage: UserInstructionSet = {
  topic: 'Add a Page',
  pages: [
    { heading: 'Add a Page', description: 'Click the + button to the right of the page selection buttons at the top of the form. Then select the type of page you want to add. There can only be one Group Registration page and Payment page.', image: require('@/assets/img/instructions/registration-form/add-page.png') },
    { heading: 'Reorder Pages', description: 'You can move a page to a different position by clicking and holding a page button and dragging it to the desired position.', image: require('@/assets/img/instructions/registration-form/move-page.png') }
  ]
}

const registrationForm_AddField: UserInstructionSet = {
  topic: 'Add a Field',
  pages: [
    { heading: 'Navigate to Page', description: 'Navigate to the form page you want to add the field to.', image: require('@/assets/img/instructions/registration-form/form-pages.png') },
    { heading: 'Add a Field', description: 'Click "Add Field" at the bottom of the form. The new field will appear at the bottom of the page.', image: require('@/assets/img/instructions/registration-form/add-field-button.png') },
    { heading: 'Field Options', description: 'Set properties for the field as desired, such as whether it is required or not. The field can be hidden from the user by toggling Hide/Show. Advanced options can be accessed by clicking the three dots at the top right of the field.', image: require('@/assets/img/instructions/registration-form/field-options.png') },
    { heading: 'Enter a Label', description: 'Enter a label for the field.', image: require('@/assets/img/instructions/registration-form/field-label.png') },
    { heading: 'Choose Field Type', description: 'Choose a field type. The field type can affect which advanced options are available and may require additional information.', image: require('@/assets/img/instructions/registration-form/field-type.png') },
    { heading: 'Choose Option Group', description: 'If you choose radio buttons, checkboxes, or dropdown as the field type, you will be presented with a dropdown list of available option groups from which to populate the field.', image: require('@/assets/img/instructions/registration-form/select-option-group.png') }
  ]
}

const registrationForm_ShowIf: UserInstructionSet = {
  topic: 'Conditionally Show a Page or Field',
  pages: [
    { heading: 'Add Show-If', description: 'Click the three dots at the top right of the page or field region, and choose "Add Show if".', image: require('@/assets/img/instructions/registration-form/add-show-if.png') },
    ...general_ShowIf.pages
  ]
}

const registrationForm_MoveField: UserInstructionSet = {
  topic: 'Move a Field to a Different Page',
  pages: [
    { heading: 'Remove Field from Current Page', description: 'Click the delete button (trash can icon) in the top right corner of the field you want to move.', image: require('@/assets/img/instructions/registration-form/delete-field.png') },
    { heading: 'Navigate to Page', description: 'Navigate to the form page you want to put the field on.', image: require('@/assets/img/instructions/registration-form/form-pages.png') },
    { heading: 'Add Existing Field', description: 'Find the field in the Existing Fields on the right. Click and hold the drag handle (two horizontal bars above the field name), and drag the field to the desired position on the page.', image: require('@/assets/img/instructions/registration-form/add-existing-field.png') }
  ]
}

const registrationForm_GroupRegistration: UserInstructionSet = {
  topic: 'Group Registration',
  pages: [
    { heading: 'Add the Group Registration Page', description: 'Click the + button to the right of the page selection buttons at the top of the form, and select Group Registration. The Group Registration page must be unique, and it must come before the Payment and Receipt pages and after all others. The "Pay For Another" field is automatically added to the Group Registration page and cannot be deleted or moved to another page.', image: require('@/assets/img/instructions/registration-form/add-page.png') }
  ]
}

const registrationForm_Payment: UserInstructionSet = {
  topic: 'Configure Payment',
  pages: [
    { heading: 'Add the Payment Page', description: 'Click the + button to the right of the page selection buttons at the top of the form, and select Payment. The Payment page must come before the Receipt page and after all others.', image: require('@/assets/img/instructions/registration-form/add-page.png') },
    { heading: 'Set Payment Processor', description: 'A number of fields are automatically added to the Payment page upon creation and must not be altered, deleted, or moved to another page. You must choose a Payment Processor on the Payment field.', image: require('@/assets/img/instructions/registration-form/payment-field.png') },
    { heading: 'Save Form', description: 'Once you have chosen a payment processor, save and deploy the form.' },
    { heading: 'Configure Payment Settings', description: 'Once you have saved and deployed the form, click "Payment" in the navigation menu to the left, and enter the integration information for your payment processor.', image: require('@/assets/img/instructions/registration-form/payment-settings.png') }
  ]
}

export const userInstructionSet_RegistrationForm: UserInstructionSet[] = [
  registrationForm_AddPage,
  registrationForm_AddField,
  registrationForm_ShowIf,
  registrationForm_MoveField,
  registrationForm_GroupRegistration,
  registrationForm_Payment,
  general_SaveAndDeploy
]

// #region Design

const design_UpdateSetting: UserInstructionSet = {
  topic: 'Modify an Element',
  pages: [
    { heading: 'Current Values', description: 'Current values for each registration type are shown as pills below the setting. Click the x to clear the current setting.', image: require('@/assets/img/instructions/design/current-values.png') },
    { heading: 'Change a Setting', description: 'Enter the desired value for the setting', image: require('@/assets/img/instructions/design/change-setting-value.png') },
    { heading: 'Select a Registration Type', description: 'Click the button for the registration type whose setting value you want to update.', image: require('@/assets/img/instructions/design/choose-reg-type.png') }
  ]
}

export const userInstructionSet_Design: UserInstructionSet[] = [
  design_UpdateSetting,
  general_SaveAndDeploy
]

// #region Imports

const imports_ImportEditConfiguration: UserInstructionSet = {
  topic: 'Import Spreadsheet Data',
  pages: [
    { heading: 'Select Import Mode', description: '"Import and Update" is recommended if you are making updates to an existing database. Check "Import First Row" if you would like to include the first row of your spreadsheet. This is unchecked by default as most first rows are a header row.', image: require('@/assets/img/instructions/importing/1-import-mode.png') },
    { heading: 'Update Based on', description: 'If "Import and Update" is chosen, you will need to select "Update Based On" and choose a common identifier. "Email" is recommended.', image: require('@/assets/img/instructions/importing/2-update-method.png') },
    { heading: 'Identify Fields', description: 'Use the dropdowns to match each field of your spreadsheet to each field in the system. You will need to do this to every field.', image: require('@/assets/img/instructions/importing/3-field-mapping.png') },
    { heading: 'Start Import or Save', description: 'Hit "Start Import" to begin adding the new records to your database. This may take up to a few minutes depending on the size of your import. You may also save your progress and start the import later.', image: require('@/assets/img/instructions/importing/4-import-button.png') }
  ]
}

export const userInstructionSet_Imports_Edit: UserInstructionSet[] = [
  imports_ImportEditConfiguration
]

// #region Emails

const emails_Create: UserInstructionSet = {
  topic: 'Create Email',
  pages: [
    { heading: 'Optionally Create a Template for Each Registration Type', description: 'If you wish, you can create a separate template for each registration type. Participants will be sent the content that corresponds to their registration type.', image: require('@/assets/img/instructions/emails/create-edit/add-registration-type.png') },
    { heading: 'Edit Registration Type Templates', description: 'You can access the template for each registration type by clicking the corresponding tab.', image: require('@/assets/img/instructions/emails/create-edit/registration-type-template.png') },
    { heading: 'Fill In Fields', description: 'Give the email a name. This will be the same for all registration type templates. Each template can have a different subject, category, CC list, and BCC list. These lists can contain comma separated email addresses to which copies of the email will be sent.', image: require('@/assets/img/instructions/emails/create-edit/meta-fields.png') },
    { heading: 'Email Content', description: 'You can add styled and formatted text and images in the content area, which is the email body.', image: require('@/assets/img/instructions/emails/create-edit/email-content.png') },
    { heading: 'Add Participant Fields From the Database', description: 'You can add fields that will be filled in at send time with data from the participant\'s record. Find the field you want to add in the Glossary on the right, and click "copy".', image: require('@/assets/img/instructions/emails/create-edit/copy-glossary-field.png') },
    { heading: 'Add Participant Fields From the Database', description: 'Paste the field into the subject or content area. It will appear as a field name surrounded by double braces.', image: require('@/assets/img/instructions/emails/create-edit/paste-glossary-field.png') },
    { heading: 'Copy and Paste Content', description: 'You can copy content from one registration type template to another within the same email. Simply click "Copy Content" on the source template, switch to the target template, and click "Paste Copied Content".', image: require('@/assets/img/instructions/emails/create-edit/copy-paste-content.png') },
    ...general_SaveAndDeploy.pages,
    { heading: 'Continue Editing', description: 'If you make any changes after creating the email, make sure you save and deploy your changes.' }
  ]
}

const emails_Edit: UserInstructionSet = {
  topic: 'Edit Email',
  pages: [
    ...emails_Create.pages,
    { heading: 'Send Test Email', description: 'You can send a test email by clicking "Send Test...". You will be prompted to select which admin users to send the test to.', image: require('@/assets/img/instructions/emails/create-edit/send-test-email.png') }
  ]
}

const emails_CreateScheduled: UserInstructionSet = {
  topic: 'Create Scheduled Email',
  pages: [
    { heading: 'Select Email to Schedule', description: 'Select the email you want to send.', image: require('@/assets/img/instructions/emails/scheduled/email-to-schedule.png') },
    { heading: 'Set a Date and Time', description: 'Click on "Select Date" and then select a date and time on the popup.', image: require('@/assets/img/instructions/emails/scheduled/scheduled-email-date.png') },
    { heading: 'Add Recipient Criteria', description: 'You can choose which participants will receive the scheduled email by checking "Add Criteria" and then adding conditions.', image: require('@/assets/img/instructions/emails/scheduled/add-criteria.png') },
    ...general_ShowIf.pages,
    { heading: 'Only Send to Participants Who Have Not Received This Email', description: 'If you are sending the same email again, you can check this box to prevent it from going to those who have already received it.', image: require('@/assets/img/instructions/emails/scheduled/only-send-to-new.png') },
    { heading: 'Create the Email', description: 'Click "Create" to schedule the email for future delivery. After this, you will be able to view the participants who will receive the email.', image: require('@/assets/img/instructions/emails/scheduled/create-scheduled-email.png') },
    { heading: 'View Recipients', description: 'Click "See Who Will Receive This Email" to view a list of participants to whom the email will be sent.', image: require('@/assets/img/instructions/emails/scheduled/view-intended-recipients.png') }
  ]
}

export const userInstructionSet_Emails_Create: UserInstructionSet[] = [
  emails_Create
]

export const userInstructionSet_Emails_Edit: UserInstructionSet[] = [
  emails_Edit
]

export const userInstructionSet_Emails_Scheduled: UserInstructionSet[] = [
  emails_CreateScheduled
]

// #region Reports

const reports_Edit: UserInstructionSet = {
  topic: 'Create a Report',
  pages: [
    ...general_ShowIf.pages,
    { heading: 'Report Type', description: 'Select the type of graph: interactive or spreadsheet. Each type will present you with different options for the data you want to display.<ul><li>Interactive: allows you to add different widgets and graphs to the report.</li><li>Spreadsheet: shows your data in a table.</li></ul>Click "Next" when you are done configuring the desired data.', image: require('@/assets/img/instructions/reports/report-type.png') },
    { heading: 'Name and Category', description: 'Enter a descriptive name for the report, and choose a category.', image: require('@/assets/img/instructions/reports/name-and-category.png') },
    { heading: 'Save Report', description: 'Click "Save" to save the report.', image: require('@/assets/img/instructions/reports/save-report.png') }
  ]
}

const reports_Spreadsheet: UserInstructionSet = {
  topic: '- Spreadsheet Report',
  pages: [
    { heading: 'Spreadsheet Report', description: 'Each row in the spreadsheet will be a participant record, and the fields you select will be the columns.', image: require('@/assets/img/instructions/reports/spreadsheet-report.png') },
    { heading: 'Select Fields', description: 'Select the fields you want to display as columns on the spreadsheet.', image: require('@/assets/img/instructions/reports/select-fields.png') },
    { heading: 'Search Fields', description: 'You can filter the list of fields by typing in the search box at the top. Clicking the "Select All" button will select all visible fields.', image: require('@/assets/img/instructions/reports/search-fields.png') },
    { heading: 'Update Fields', description: 'Click "Update" to save your selection.', image: require('@/assets/img/instructions/general/update-button.png') },
    { heading: 'Sort Columns and Rows', description: 'You can sort the columns on the spreadsheet by using the drag handle on each field (horizontal bars) to drag the fields into the desired order. Use the radio buttons to select the field by which to sort participants.', image: require('@/assets/img/instructions/reports/sort-fields.png') }
  ]
}

const reports_Interactive: UserInstructionSet = {
  topic: '- Interactive Report',
  pages: [
    { heading: 'Interactive Report', description: 'An interactive report allows you to add multiple sections, or widgets, that display different data. Click "Add Report Widget" for each widget you want to add.', image: require('@/assets/img/instructions/reports/interactive-report.png') },
    { heading: 'Add a Widget', description: 'You can choose from several types of widgets.<ul><li>Grid: a table of data similar to a spreadsheet.</li><li>Graph: a pie, bar, or polar graph.</li><li>Chart: rows containing bar chart and count of each value.</li><li>Number: either the count or sum of values.</li><li>Text: static text.</li></ul>Widgets can be sorted using the drag handles (horizontal bars) at the top of each widget.', image: require('@/assets/img/instructions/reports/widget-type.png') },
    { heading: 'Grid Widget', description: 'Like a spreadsheet report, you can select participant data fields to display as columns. Participants will be shown as rows.', image: require('@/assets/img/instructions/reports/grid-widget.png') },
    { heading: 'Graph Widget', description: 'Enter a name to be used as the heading for that widget.<br />Choose the type of graph to use: pie, bar, or polar.<br />Select the field whose values will be shown on the graph.', image: require('@/assets/img/instructions/reports/graph-widget.png') },
    { heading: 'Graph Widget Example', description: 'Each piece of the graph represents the percentage of the total made up of the corresponding field value.', image: require('@/assets/img/instructions/reports/graph-widget-example.png') },
    { heading: 'Chart Widget', description: 'A row will be generated for each unique value of the selected field. It will display the value, its percentage of the total, and how many participants selected it.', image: require('@/assets/img/instructions/reports/chart-widget.png') },
    { heading: 'Chart Widget Example', description: 'Each row displays a unique value, its percentage of the total, and how many participants selected it.', image: require('@/assets/img/instructions/reports/chart-widget-example.png') },
    { heading: 'Number Widget', description: 'Choose whether to display the number of unique values for the selected field or the sum of the values. Sum only applies to numeric fields.', image: require('@/assets/img/instructions/reports/number-widget.png') },
    { heading: 'Text Widget', description: 'Enter the text you want to display.', image: require('@/assets/img/instructions/reports/text-widget.png') }
  ]
}

export const userInstructionSet_Reports_Edit: UserInstructionSet[] = [
  reports_Edit,
  reports_Spreadsheet,
  reports_Interactive
]

// #region Promo Codes

const promoCodes_Edit: UserInstructionSet = {
  topic: 'Edit Promo Code',
  pages: [
    { heading: 'Discount Amount/Percentage', description: 'You can choose whether the code will discount by a fixed amount or a percentage of the price.', image: require('@/assets/img/instructions/promo-codes/discount-amount-percent.png') },
    { heading: 'Optional Start/End Dates', description: 'If you want to specify a start time, end time, or both for when the code is usable, enter the "Available From" and/or "Available To" dates.', image: require('@/assets/img/instructions/promo-codes/available-from-to.png') },
    { heading: 'Group Discount Type', description: '<ul><li>Single Group Member: each group member has to enter the code on the registration form individually to get the discount.</li><li>All Group Members: all group members will receive the discount.</li></ul>', image: require('@/assets/img/instructions/promo-codes/group-discount-type.png') },
    { heading: 'Cap', description: 'If desired, enter the number of times the code may be used.', image: require('@/assets/img/instructions/promo-codes/cap.png') },
    { heading: 'Registration Type Specific', description: 'If enabled, you may select the registration types that may apply the code(s).', image: require('@/assets/img/instructions/promo-codes/registration-type-specific.png') },
    { heading: 'Option Specific', description: 'If enabled, you may select the options to which the code(s) may be applied.', image: require('@/assets/img/instructions/promo-codes/option-specific.png') },
    { heading: 'Notes', description: 'Optional notes only visible to administrators.', image: require('@/assets/img/instructions/promo-codes/notes.png') },
    { heading: 'Save the Code(s)', description: 'Click "Save" to save your promo code(s)', image: require('@/assets/img/instructions/general/save-button.png') }
  ]
}

const promoCodes_Create: UserInstructionSet = {
  topic: 'Create Promo Code(s)',
  pages: [
    { heading: 'Single Code', description: 'If you only want to make a single promo code, you can manually enter the code or have it automatically generated for you.', image: require('@/assets/img/instructions/promo-codes/single.png') },
    { heading: 'Multiple Codes', description: 'If you want to create multiple codes at once, they will be automatically generated.', image: require('@/assets/img/instructions/promo-codes/multiple.png') },
    ...promoCodes_Edit.pages
  ]
}

export const userInstructionSet_PromoCodes_Edit: UserInstructionSet[] = [
  promoCodes_Edit
]

export const userInstructionSet_PromoCodes_Create: UserInstructionSet[] = [
  promoCodes_Create
]
